<template>
  <div class="container">
    <div class="content-box">
      <h1>Screenshots</h1>

      <div>Screenshots from development process. This is only initial version and it's not how the game will look when finished and polished.</div>

      <ImagesGrid :images="result.fields.images" v-if="result" class="screenshots"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ImagesGrid from '@/components/ImagesGrid'

export default {
  name: 'MediaView',
  components: {
    ImagesGrid
  },
  metaInfo () {
    return {
      title: 'Guilds & Blades - Screenshots'
    }
  },
  computed: {
    ...mapState('media', ['result'])
  },
  mounted () {
    this.fetchPage()
  },
  methods: {
    ...mapActions('media', [
      'fetchPage'
    ])
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.screenshots {
  margin-top: $spacing2;
}
</style>
