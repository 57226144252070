<template>
  <div>
    <div class="screenshots" v-if="images">
      <template v-for="(screenshot, index) in images">
        <div class="screenshots__image-container" :key="screenshot.fileName">
          <img
            :src="`${screenshot.fields.file.url}?w=${imageWidth}&fm=webp`"
            :alt="screenshot.fields.title"
            :width="imageWidth"
            @click="
              (lightboxOpen = !lightboxOpen), (lightboxImageIndex = index + 1)
            "
          />
        </div>
      </template>
    </div>

    <FsLightbox
      :toggler="lightboxOpen"
      :sources="lightboxImageSources"
      :slide="lightboxImageIndex"
    />
  </div>
</template>

<script>
import FsLightbox from 'fslightbox-vue'

export default {
  name: 'ImagesGrid',
  components: {
    FsLightbox
  },
  data () {
    return {
      lightboxOpen: false,
      lightboxImageIndex: 0,
      imageWidth: window.innerWidth < 960 ? (window.innerWidth - 20) : 300
    }
  },
  props: {
    images: Array
  },
  mounted () {
    window.onresize = () => {
      this.imageWidth = window.innerWidth < 960 ? (window.innerWidth - 20) : 300
    }
  },
  computed: {
    lightboxImageSources () {
      if (!this.images) {
        return []
      }

      return this.images.map(image => image.fields.file.url + '?fm=webp')
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/variables.scss";

.screenshots {
  @media (min-width: $breakpointDesktopLg) {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
  }

  &__image-container {
    display: grid;
    margin-top: $spacing1;

    @media (min-width: $breakpointDesktopLg) {
      margin: 0;
    }

    img {
      width: 100%;
      &:hover {
        filter: contrast(120%) grayscale(10%);
        transition-property: filter;
        transition-duration: 0.5s;
        cursor: pointer;
      }

      &:active {
        filter: saturate(2);
        transition-property: filter;
        transition-duration: 0.1s;
      }
    }

    &:hover {
      transition-property: filter;
      transition-duration: 0.5s;
    }
  }
}
</style>
